import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import AdminTools from './adminTools'
import ResultViewer from './resultViewer';
import UserPage from './userPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" Component={UserPage}/>
        <Route exact path="/myadmin" Component={AdminTools} />
        <Route exact path='/result' Component={ResultViewer}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
