import React from 'react'
import UserButton from './triviaUser'

import "./style.css"

class UserPage extends React.Component{
    render(){
        return(
            <UserButton/>
        );
    }
}

export default UserPage