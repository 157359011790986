import React from 'react'
import { ShowResult, AddCountButton, ResetButton, ToggleLockState} from './trivia'

import "./style.css"

class AdminTools extends React.Component{
    render(){
        return(
            <>
            <div id="main">
                <ShowResult></ShowResult>
                <ToggleLockState></ToggleLockState>
                <AddCountButton></AddCountButton>
                <ResetButton></ResetButton>
            </div>
            </>
        );
    }
}

export default AdminTools