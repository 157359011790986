import { ApiServerUrl } from "./setting";
import axios from "axios"
import { useState, useEffect } from "react";

export function ShowResult(){
    const [count, SetCount] = useState(0);

    useEffect(() => {
        async function fetchCount(){
            const params = new URLSearchParams();
            axios
                .get(ApiServerUrl + "/result",params)
                .then(res => {
                    console.log(res.data);
                    SetCount(res.data);
                })
                .catch(error =>{
                    alert(error);
                });
        }

        const interval = setInterval(() => {
            fetchCount();
        }, 1000);

        return () => clearInterval(interval);
    });

    return(
        <div id="result">
            <p> {count} <font size="7">へぇ</font></p>
        </div>
    );
}

function PostApi(api_action){
    const params = new URLSearchParams();
    axios
        .post(ApiServerUrl + api_action, params)
        .then(res => {
        })
        .catch(error =>{
            alert("Error");
        });
}

function IButtonFunc(button_label, api_action){
    const [state, setState] = useState(0);

    const onClick = () =>{
        setState(state);
        PostApi(api_action)
    };

    return(
        <div id="main">
            <section>
                <div id="main">
                    <button type='button' onClick={onClick}>{button_label}</button>
                </div>
            </section>
        </div>
    );
}


function AddCountButtonImpl(count){
    return(
        IButtonFunc("へぇ", "/add/" + count)
    )
}

export function ToggleLockState(){
    const [state, setState] = useState(0);

    const handleToggle = () => {
        setState(!state);
        if(!state)
        {
            PostApi("/lock");
        }
        else
        {
            PostApi("/unlock");
        }
    }

    return(
        <label className="toggle">
            <input type="checkbox" checked={state} onChange={handleToggle} />
            <div className="moving-area">
                <div className="toggle-on">LOCK</div>
                <div className="toggle-off">UNLOCK</div>
            </div>
        <span className="toggle-button"></span>
        </label>
    )
}

export function AddCountButton(){
    return(
        AddCountButtonImpl(1)
    )
}

export function ResetButton(){
    return(
        IButtonFunc("リセット", "/reset")
    );
}

export function LockAddButton(){
    return(
        IButtonFunc("カウント停止", "/lock")
    );
}

export function UnlockAddButton(){
    return(
        IButtonFunc("カウント再会", "/unlock")
    );
}