import { ApiServerUrl } from "./setting";
import { useState, useEffect } from "react";
import axios from "axios";

let he_Array = [];
for (var i=0; i < 10; i++){
    he_Array.push(new Audio("hee_svvwkiX.mp3"));
}

function PostApi(api_action){
    const params = new URLSearchParams();
    axios
        .post(ApiServerUrl + api_action, params)
        .then(res => {
        })
        .catch(error =>{
        });
}

export function UserButton(){
    const [netCount, SetNetCount] = useState(0);
    const [notSubmitcount, SetNotsubmitCount] = useState(0)


    useEffect(() =>{
        async function submitCount(){
            PostApi("/add/" + notSubmitcount);
            SetNotsubmitCount(0);
        }

        const interval = setInterval(() => {
            submitCount();
        }, 3000);

        return () =>clearInterval(interval);
    });


    const onClick = () =>{
        SetNetCount(netCount + 1);
        SetNotsubmitCount(notSubmitcount + 1);
        console.log("NetCount:" + netCount);
        console.log("NotSubmit:" + notSubmitcount);
        let he_index = netCount % 10;
        he_Array[he_index].play();

        const he_text_element = document.getElementById("he_text_" + he_index);
        he_text_element.className = "";
        window.requestAnimationFrame(function(time) {
            window.requestAnimationFrame(function(time) {
                he_text_element.className = "he-text";
            });
        });
    }


    return(
        <div className="user-button">
            <br></br>
            <button onClick={onClick} ></button>
            <div id="he_text_0" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_1" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_2" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_3" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_4" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_5" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_6" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_7" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_8" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
            <div id="he_text_9" style={{"opacity":"0%", "UserSelect":"none"}}>へぇ</div>
        </div>
    );

}

export default UserButton