import React from 'react'
import { ShowResult} from "./trivia"


class ResultViewer extends React.Component{
    render(){
        return(
            <div id="main">
                <ShowResult></ShowResult>
            </div>
        );
    }
}

export default ResultViewer